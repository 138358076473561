@import url(https://s3.amazonaws.com/clientevents/Public/WCNBPCBJQV2/fontcss.css);
body {
    margin: 0;
}

@charset "UTF-8";
/* CSS Document */

body {
	font-family:'Conv_Artifakt Element Regular', Arial, sans-serif; 
}

